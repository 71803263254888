
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  body {
    @apply font-inter lg:overflow-x-hidden
  }
  .link-text {
      @apply lg:text-sm xl:text-base md:text-xs;
  }
  .mx-container{
    @apply container mx-auto px-7 md:px-10 xl:px-14 2xl:px-16 3xl:px-20
  }
}

@font-face {
  font-family: "Regular";
  src: local("AzoSans")
  url('./assets/fonts/AzoSans.otf') format('opentype');
}


@media (min-width: 768px) {
  .md-hide {
    display: none;
  }
}
@media (max-width: 767px) {
  .ms-hide {
    display: none;
  }
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
